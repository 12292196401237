import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

const Kontakt = ({ location }) => (
  <Layout location={location}>
    <SEO
      title="Kontakt"
      description="Formular zur Kontaktaufnahme zu den kombinat kickers mannheim."
    />
    <div>
      <h1>Kontakt</h1>
      <form
        name="contact"
        method="post"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        action="/danke"
      >
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="contact" />
        <div className="form-element">
          <label htmlFor="name">Dein Name</label>
          <br />
          <input type="text" name="name" id="name" />
        </div>
        <div className="form-element">
          <label htmlFor="email">Deine E-Mailadresse</label>
          <br />
          <input type="text" name="email" id="email" />
        </div>
        <div className="form-element">
          <label htmlFor="message">Deine Nachricht an uns</label>
          <br />
          <textarea name="message" id="message" rows="6" />
        </div>
        <button type="submit" className="button btn-main">
          Abschicken
        </button>
        <button type="reset" className="button btn-secondary">
          Formular leeren
        </button>
      </form>
    </div>
  </Layout>
);

export default Kontakt;
